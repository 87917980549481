/* 
Any CSS added to this file or imported from this file, e.g. `@import '../stylesheets/my-css.css'`,
will be included in the "application" pack. Any CSS imported from application.js or as part of the 
application.js dependency graph, e.g. `import '../stylesheets/my-css.css'` will also be included 
in the "application" pack. 

To reference this file, add <%= stylesheet_pack_tag 'application' %> to the appropriate
layout file, like app/views/layouts/application.html.erb
*/

/* tailwind without base - base is a separate entrypoint */

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.header-navigation {
  margin: 0 -1rem;
}

.header-navigation a {
  color: currentColor;
}

/** links **/

.header-navigation li > a {
  display: block;
  white-space: nowrap;
  font-size: 0.875rem;
  text-decoration: none;
}

.header-navigation li > a:hover,
  .header-navigation li > a:active {
  color: currentColor;
  text-decoration: underline;
}

/** top level navigation **/

.header-navigation > ul {
  display: flex;
}

.header-navigation > ul > li {
  position: relative;
}

.header-navigation > ul > li:hover {
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity));
}

.header-navigation > ul > li > a {
  padding: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
}

/** second level navigation **/

.header-navigation > ul > li > ul {
  display: none;
  line-height: 2;
}

.header-navigation > ul > li > ul > li > a {
  padding-left: 2.5rem;
  padding-right: 5rem;
}

.header-navigation > ul > li:hover > ul {
  display: flex;
  flex-direction: column;
  position: absolute;
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity));
  padding-top: 1rem;
  padding-bottom: 2rem;
  z-index: 30;
  top: 100%;
  left: 0;
  min-width: 120%;
}

/** categories navigation **/

.header-navigation > .header-navigation__categories {
  flex-grow: 1;
  justify-content: space-between;
}

.header-navigation > .header-navigation__menu-items {
  margin-left: 5%;
}

.m-dropdown {
}

.m-dropdown__menu {
  display: none;
}

.m-dropdown--visible .m-dropdown__menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 30;
  top: 100%;
  right: 0;
}

.pagy-nav,
  .pagy-nav-js {
  display: flex; /* space-x-2;*/
}

.pagy-nav .page a,
  .pagy-nav .page.active,
  .pagy-nav .page.prev.disabled,
  .pagy-nav .page.next.disabled,
  .pagy-nav-js .page a,
  .pagy-nav-js .page.active,
  .pagy-nav-js .page.prev.disabled,
  .pagy-nav-js .page.next.disabled {
  display: block;
  border-radius: 0.125rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
  font-weight: 600;
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
  border-color: currentColor;
  &:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }
  &:active {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }
}

.pagy-nav .page.prev.disabled,
  .pagy-nav .page.next.disabled,
  .pagy-nav-js .page.prev.disabled,
  .pagy-nav-js .page.next.disabled {
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity));
  cursor: default;
  &:hover {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }
  &:active {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }
}

.pagy-nav .page.active,
  .pagy-nav-js .page.active {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  cursor: default;
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity));
  &:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }
  &:active {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }
}

.pagy-combo-nav-js {
  display: flex;
  border-radius: 9999px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
  font-weight: 600;
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
  border-color: currentColor;
}

.pagy-combo-nav-js .pagy-combo-input {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.125rem
}

.pagy-combo-nav-js .page.prev,
  .pagy-combo-nav-js .page.next {
  &:hover {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }
  &:active {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }
}

.pagy-combo-nav-js .page.prev.disabled,
  .pagy-combo-nav-js .page.next.disabled {
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity));
  cursor: default;
}

.space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)));
}

.space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)));
}

.space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1rem * var(--space-y-reverse));
}

.space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1rem * var(--space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse)));
}

.space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)));
}

.space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(3rem * var(--space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--space-x-reverse)));
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.bg-transparent {
  background-color: transparent;
}

.bg-black {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.bg-gray-800 {
  --bg-opacity: 1;
  background-color: #2d3748;
  background-color: rgba(45, 55, 72, var(--bg-opacity));
}

.bg-orange-200 {
  --bg-opacity: 1;
  background-color: #feebc8;
  background-color: rgba(254, 235, 200, var(--bg-opacity));
}

.bg-yellow-100 {
  --bg-opacity: 1;
  background-color: #fffff0;
  background-color: rgba(255, 255, 240, var(--bg-opacity));
}

.bg-yellow-200 {
  --bg-opacity: 1;
  background-color: #fefcbf;
  background-color: rgba(254, 252, 191, var(--bg-opacity));
}

.bg-yellow-accent {
  --bg-opacity: 1;
  background-color: #fecc09;
  background-color: rgba(254, 204, 9, var(--bg-opacity));
}

.bg-teal-400 {
  --bg-opacity: 1;
  background-color: #4fd1c5;
  background-color: rgba(79, 209, 197, var(--bg-opacity));
}

.bg-blue-700 {
  --bg-opacity: 1;
  background-color: #2b6cb0;
  background-color: rgba(43, 108, 176, var(--bg-opacity));
}

.bg-opacity-25 {
  --bg-opacity: 0.25;
}

.border-collapse {
  border-collapse: collapse;
}

.border-transparent {
  border-color: transparent;
}

.border-current {
  border-color: currentColor;
}

.border-gray-400 {
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity));
}

.border-red-500 {
  --border-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--border-opacity));
}

.border-teal-600 {
  --border-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--border-opacity));
}

.rounded-none {
  border-radius: 0;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.border-0 {
  border-width: 0;
}

.border {
  border-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-none {
  flex: none;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-48 {
  height: 12rem;
}

.h-full {
  height: 100%;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.leading-tight {
  line-height: 1.25;
}

.leading-loose {
  line-height: 2;
}

.list-none {
  list-style-type: none;
}

.list-decimal {
  list-style-type: decimal;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.max-w-none {
  max-width: none;
}

.object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.object-bottom {
  -o-object-position: bottom;
     object-position: bottom;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-100 {
  opacity: 1;
}

.overflow-auto {
  overflow: auto;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.p-1\/4 {
  padding: 25%;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.placeholder-gray-700::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity));
}

.placeholder-gray-700:-ms-input-placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity));
}

.placeholder-gray-700::placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity));
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.resize {
  resize: both;
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.fill-current {
  fill: currentColor;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-current {
  color: currentColor;
}

.text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.text-gray-300 {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity));
}

.text-gray-500 {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
}

.text-gray-600 {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity));
}

.text-gray-700 {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity));
}

.text-gray-900 {
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity));
}

.text-yellow-500 {
  --text-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--text-opacity));
}

.text-green-500 {
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity));
}

.text-blue-500 {
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity));
}

.hover\:text-white:hover {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.w-2 {
  width: 0.5rem;
}

.w-4 {
  width: 1rem;
}

.w-6 {
  width: 1.5rem;
}

.w-24 {
  width: 6rem;
}

.w-64 {
  width: 16rem;
}

.w-auto {
  width: auto;
}

.w-1\/2 {
  width: 50%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-full {
  width: 100%;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.gap-4 {
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.gap-8 {
  grid-gap: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.translate-x-0 {
  --transform-translate-x: 0;
}

.-translate-x-full {
  --transform-translate-x: -100%;
}

.transition-all {
  transition-property: all;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
}

.transition-opacity {
  transition-property: opacity;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.duration-300 {
  transition-duration: 300ms;
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.link-clear,
  .link-clear:visited,
  .link-clear:focus,
  .link-clear:hover,
  .link-clear:active {
  text-decoration: inherit;
  color: inherit;
}

@media (min-width: 640px) {
  .sm\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .sm\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .sm\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .sm\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .sm\:container {
      max-width: 1280px;
    }
  }

  .sm\:w-full {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .md\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .md\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .md\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .md\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .md\:container {
      max-width: 1280px;
    }
  }

  .md\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .md\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:flex-grow {
    flex-grow: 1;
  }

  .md\:h-8 {
    height: 2rem;
  }

  .md\:h-64 {
    height: 16rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
  }

  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md\:mt-12 {
    margin-top: 3rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:w-8 {
    width: 2rem;
  }

  .md\:w-32 {
    width: 8rem;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }

  .md\:w-2\/3 {
    width: 66.666667%;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .lg\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .lg\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .lg\:container {
      max-width: 1280px;
    }
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:h-8 {
    height: 2rem;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:text-sm {
    font-size: 0.875rem;
  }

  .lg\:text-base {
    font-size: 1rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
  }

  .lg\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:mt-32 {
    margin-top: 8rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:w-8 {
    width: 2rem;
  }

  .lg\:w-64 {
    width: 16rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:gap-6 {
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .lg\:gap-12 {
    grid-gap: 3rem;
    grid-gap: 3rem;
    gap: 3rem;
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .xl\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .xl\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .xl\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .xl\:container {
      max-width: 1280px;
    }
  }

  .xl\:gap-20 {
    grid-gap: 5rem;
    grid-gap: 5rem;
    gap: 5rem;
  }
}
