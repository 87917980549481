/* 
Any CSS added to this file or imported from this file, e.g. `@import '../stylesheets/my-css.css'`,
will be included in the "application" pack. Any CSS imported from application.js or as part of the 
application.js dependency graph, e.g. `import '../stylesheets/my-css.css'` will also be included 
in the "application" pack. 

To reference this file, add <%= stylesheet_pack_tag 'application' %> to the appropriate
layout file, like app/views/layouts/application.html.erb
*/

/* tailwind without base - base is a separate entrypoint */
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import '../stylesheets/header-navigation';
@import '../stylesheets/dropdown';
@import '../stylesheets/pagy';

@layer utilities {
  .link-clear,
  .link-clear:visited,
  .link-clear:focus,
  .link-clear:hover,
  .link-clear:active {
    text-decoration: inherit;
    color: inherit;
  }
}